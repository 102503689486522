.location-details {
    .card {
        hr {
            margin-bottom: 2.3rem;
            margin-top: 2rem;
        }
    }
}

.location-saver {
    display: flex;
    justify-content: space-between;
    padding: 3.2rem 2rem 2rem;
    border-radius: .6rem;
    box-shadow: 0 .6rem 2.3rem rgba(0,0,0,.05);
    position: fixed;
    width: 33.6rem;
    bottom: 3vh;
    left: calc(50% + 125px);
    transform: translateX(-50%);
    z-index: 100;

    .close {
        width: .9rem;
        height: .9rem;
        top: .8rem;
        right: .8rem;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.fieldset {
    legend {
        font-weight: 600;
        margin: 0;
        font-size: $font-size-base;
    }
}

.fieldset-hours {
    margin-bottom: 1.2rem;
    legend {
        width: 33.3333%;
    }

    .form-group {
        width: 33.3333%;
        padding-top: 0;
        padding-left: .5rem;
        padding-right: .5rem;

        + .form-group {
            margin-top: 0;
        }
    }
}

.list-update-locations {
    @include media-breakpoint-up(md) {
        column-count: 2;
    }

    li {
        break-inside: avoid-column;
        margin-bottom: 3rem;
    }

    .btn {
        margin-right: 1.6rem;
    }
}