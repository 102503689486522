//
// leaflet map
//
@import '~leaflet/dist/leaflet.css';

.leaflet-container {
    z-index: 99;
}

.leaflet-default-icon-path {
    background-image: url('/images/leaflet/marker-icon.png');
}