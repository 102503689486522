// 
// projects.scss
//

// project list

.project-list-table{
  border-collapse:separate; 
  border-spacing:0 5px; 

  th {
    border-bottom: 1px solid $gray-700;
  }

  tbody {

    &::before {
      /* hack to add space between thead and tbody */
      height:1rem;
      content:"";
      color:white; /* bacground color */
      display:block;
    }

    tr{
      background-color: $card-bg;
      border-radius: $border-radius;
      td {
        &:first-child {
          border-radius: $border-radius 0 0 $border-radius;
        }
        &:last-child {
          border-radius: 0 $border-radius $border-radius 0;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .form-switch-md {
    pointer-events: none;
  }
}