//
// Forms.scss
//

#app, .modal {

  .form-group {
    margin-bottom: 0;
    line-height: 1.69;
  }

  label {
      margin-bottom: 0;
  }

  .mx-datepicker .mx-icon-calendar {
      display: none;
  }

  .mx-input {
      border: 0;
      font-size: $font-size-base;
  }
}


[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left
      /*rtl: right*/
    ;
  }
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}


// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}



// checkbox

.form-checkbox-outline {

  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    background-color: $card-bg;
    position: relative;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type=checkbox] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '';
        top: 3px !important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}

// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          background-color: $value;
        }
      }
    }
  }
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}


.form-switch {
  padding-left: 0;
  .form-check-input {
    margin-left: 0;
  }
}
// Switch sizes

.form-switch-md {
  min-height: 24px;
  line-height: 24px;

  .form-check-input {
    width: 40px;
    height: 20px;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}


.form-switch-lg {
  min-height: 28px;
  line-height: 28px;

  .form-check-input {
    width: 48px;
    height: 24px;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}



.custom-radio {
  .custom-control-input {
    appearance: none;
    width: $form-check-input-width;
    height: $form-check-input-width;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    vertical-align: middle;


    &[type="radio"] {
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $form-check-radio-border-radius;

      &:focus {
        outline: none;
      }
    }

    &:checked {
      background-color: $form-check-input-checked-bg-color;
      border-color: $form-check-input-checked-border-color;

      &[type=radio] {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }

  }
}


.custom-checkbox {
  .custom-control-input {
    appearance: none;
    width: $form-check-input-width;
    height: $form-check-input-width;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    vertical-align: middle;

    &[type="checkbox"] {
      &:focus {
        outline: none;
      }
    }

    &:checked {
      background-color: $form-check-input-checked-bg-color;
      border-color: $form-check-input-checked-border-color;

      &[type=checkbox] {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

  }
}

.form-group {
  padding-top: 1rem;
}

.form-select {
  border-radius: 0;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  &[size] {
    border-width: 1px;
    border-radius: $border-radius;
  }
}


.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  &.no-border {
    border: 0;
  }
}

input[type="search"].form-control {
  height: 5rem;
  padding-left: 2rem;
  background-image: url(/images/icons/search.svg);
  background-repeat: no-repeat;
  background-position: right 2rem center;
  border: 1px solid $gray-400;
}

.label-placeholder {
  position: relative;
  padding-top: 2.5rem;

  label {
    position: absolute;
    top: calc(100% -  7px);
    transform: translateY(-100%);
    left: 0;
    color: $gray-600;
    transition: top 300ms, color 300ms;
  }

  &.is-focused {
    label {
      top: .7rem;
      transform: translateY(0);
      color: $body-color;
    }
  }
}

// Adds control sizing to Bootstrap custom checkbox inputs
$b-custom-control-indicator-size-lg: 1rem * 2.25;
$b-custom-control-indicator-size-sm: 1rem * 0.875;

// Indicator background
$b-custom-control-indicator-bg-size-lg: 50% 50%;
$b-custom-control-indicator-bg-size-sm: 50% 50%;

// Gutter widths
$b-custom-control-gutter-lg: .5rem * 1.25;
$b-custom-control-gutter-sm: .5rem * 0.875;

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: .5rem + 1rem;
  color-adjust: exact; // Keep themed appearance for print

  + .custom-control {
    margin-top: 2.6rem;
  }
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: 1rem;
  height: ($font-size-base * $line-height-base + 1rem) * .5;
  opacity: 0;

  &:checked ~ .custom-control-label::before {
    color: $component-active-color;
    border-color: $success;
    background-color: rgba($success, .1);
  }

  &:focus ~ .custom-control-label::before {
    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow ;
    } @else {
      box-shadow: $input-focus-box-shadow ;
    }
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $input-focus-border-color;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $component-active-color;
    background-color: lighten($component-active-bg, 35%);
    border-color: lighten($component-active-bg, 35%);
    @include box-shadow(null);
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .custom-control-label {
      color: $gray-600;

      &::before {
        background-color: $input-disabled-bg;
      }
    }
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: null;
  vertical-align: top;
  cursor: null;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - 1rem) * .5;
    left: -(.5rem + 1rem);
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: $input-bg;
    border: $gray-700 solid $input-border-width;
    @include box-shadow($input-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: ($font-size-base * $line-height-base - 1rem) * .5;
    left: -(.5rem + 1rem);
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / #{50% 50%} no-repeat;
  }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
  .custom-control-label::before {
    @include border-radius($border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.235 7.957a.666.666 0 0 1-.487-.198L.72 4.73a.68.68 0 0 1 0-.973.68.68 0 0 1 .974 0l2.556 2.54L10.322.242a.68.68 0 0 1 .974 0 .68.68 0 0 1 0 .974L4.737 7.76a.726.726 0 0 1-.502.198Z' fill='#{$success}'/></svg>"));
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg(rgba($primary, .5));
    }
    &:indeterminate ~ .custom-control-label::before {
      @include gradient-bg(rgba($primary, .5));
    }
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-label::before {
    border-radius: 50%;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.235 7.957a.666.666 0 0 1-.487-.198L.72 4.73a.68.68 0 0 1 0-.973.68.68 0 0 1 .974 0l2.556 2.54L10.322.242a.68.68 0 0 1 .974 0 .68.68 0 0 1 0 .974L4.737 7.76a.726.726 0 0 1-.502.198Z' fill='#{$success}'/></svg>"));
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg(rgba($primary, .5));
    }
  }
}


.custom-control-label::before{
  @include transition(background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out);
}

.custom-checkbox.b-custom-control-lg, .custom-radio.b-custom-control-lg {
  .custom-control-label::before {
    width: $b-custom-control-indicator-size-lg;
    height: $b-custom-control-indicator-size-lg;
  }

  .custom-control-label::after {
    width: 1.2rem;
    height: .8rem;
    background-size: contain;
  }
}

.custom-checkbox.b-custom-control-lg {
  .custom-control-label::before {
    @include border-radius($border-radius-lg);
  }
}

.custom-checkbox.b-custom-control-sm, .custom-radio.b-custom-control-sm {
  .custom-control-label::before {
    width: $b-custom-control-indicator-size-sm;
    height: $b-custom-control-indicator-size-sm;
  }

  .custom-control-label::after {
    width: $b-custom-control-indicator-size-sm;
    height: $b-custom-control-indicator-size-sm;
    background-size: $b-custom-control-indicator-bg-size-sm;
  }
}

.custom-checkbox.b-custom-control-sm {
  .custom-control-label::before {
    @include border-radius($border-radius-sm);
  }
}

.custom-checkbox, .custom-radio {
  padding-left: 0;
  .custom-control-label{
    display: flex;
    align-items: center;
    line-height: 1;
    &::before {
      position: relative;
      top: auto;
      left: auto;
      margin-right: 1rem;
    }

    &::after {
      top: auto;
      left: .6rem;
    }
  }
}


.vlabeledit {
  position: relative;
  label, .label {
    font-weight: 600;
  }

  .vlabeledit-label, .vlabeledit-input, .vlabeledit-static {
    margin-left: auto;
    width: 59.5%;
  }
}