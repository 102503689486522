.tooltip.b-tooltip {
    display: block;
    opacity: $tooltip-opacity;
    outline: 0;
}

.tooltip-inner {
    border: 2px solid $primary;
}

.btn-tooltip {
    border: 1px solid currentColor;
    border-radius: 50%;
    padding: 0;
    width: 1.2rem;
    height: 1.2rem;
    line-height: .5;
    color: $body-color;
    margin-left: .5rem;
    margin-top: -.2rem
}