body, html {
    /* Set default size to 10
       All rems can now be based on 10 instead of 16
       1.5rem = 15px vs 1.5rem = 24px
       (easier math!)
    */
    font-size: .625rem;
}

body {
    font-size: $font-size-base;
}

.text-md {
    font-size: 1.5rem;
}

.sr-only {
    @extend .visually-hidden;
}