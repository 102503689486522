// 
// _card.scss
// 

.card {
  margin-bottom: 4rem;
  box-shadow: 4px 4px 0 $gray-400;

  &.bg-none {
    box-shadow: none;

    .card-body {
      @include media-breakpoint-up(lg) {
        padding: 0 5rem;
      }
    }
  }

  .table {
    line-height: 1.69;

    > :not(caption) > * > * {
      background: none;
      box-shadow: none;
      border-bottom: 0;
      padding: 0;
    }

    > tbody > tr, > tr {
      > th, > td {
        padding-top: .6rem;
        padding-bottom: .6rem;
      }
    }

    > :not(:first-child) {
      border: 0;
    }

    th:first-child, td:first-child {
      padding-left: 0;
    }
    th {
      font-weight: 600;
    }
  }
}

.card-body {
  padding-right: 1.5rem;
  padding-bottom: 2.5rem;
}

.card-drop {
  color: $body-color;

  .dropdown-menu {
    padding: 2.7rem 2rem;
  }

  li {
    + li {
      margin-top: 1.5rem;
    }
  }

  .btn-label {
    text-align: left;
    color: $body-color;
  }
}

.card-title {
  font-size: $font-size-base;
  margin: 0 0 1.8rem 0;
  padding-bottom: 1.3rem;
  font-weight: $font-weight-bold;
  border-bottom: 2px solid $body-color;
  letter-spacing: .15em;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

