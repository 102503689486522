.modal-header,
.alert {
    .close {
        line-height: 1;
        padding: 0;
        background: transparent;
        border: 0;
        position: absolute;
        top: 2.1rem;
        right: 2.2rem;
        width: 1.6rem;
        height: 1.6rem;
        z-index: 2;

        button {
            display: none;
        }
    }
}

.modal-header {
    padding: 2.1rem 0 0 2.6rem;
}

.dataTables_length {
    select {
        margin-left: 5px;
        margin-right: 5px;
    }
}
.datepicker__input {
    background-color: $input-bg;
    height: $input-height !important;
    line-height: 2.5 !important;
}
.vnis__input, .datepicker__wrapper, .datepicker__inner, .datepicker__input {
    background-color: $input-bg !important;
    color: $input-color !important;
    border-color: $input-border-color !important;
}
.datepicker__dummy-wrapper{
    overflow: hidden;
    border-color: $input-border-color !important;
}
.datepicker__input:focus {
    outline: 0px solid !important;
}
// .datepicker__week-row {
//     border-bottom: 5px solid $hr-border-color !important;
// }
// .datepicker__months:before {
//     background: $hr-border-color !important;
// }
.datepicker__month-day--allowed-checkout:hover, .datepicker__month-day--valid:hover {
    background-color: $primary !important;
    color: $white !important;
}
.mx-datepicker {
    .mx-icon-calendar{
        color: $input-color !important;
    }
}

.blog-card {
    > h5 {
        position: absolute;
        margin-top: 10px !important;    
    }
    .tabs {
        width: 100%;
        .card-body {
            padding: 1.25rem 0 0;
        }
    }
}


.modal-backdrop {
    opacity: .5;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    height: $input-height !important;
    line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
    }
}

.vlabeledit {
    cursor: pointer;
}

.custom-fields-table tr {
    cursor: pointer;
}

.hours-table {
    .vdatetime input.vdatetime-input {
        width: 100%;
    }
    .mx-datepicker .mx-icon-calendar {
        display: none !important;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.client-location-wrapper .card-button-wrapper,
.customfield-cards-wrapper .card-button-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.settings-switch {
    width: 255px;
}
