// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-400 {
  background-color: $gray-400;
  color: $body-color;
}
.btn-700 {
  background-color: $gray-700;
  color: #fff;
  &:hover {
    background-color: $gray-500;
    color: #fff;
  }
}

.btn-outline-dark, .btn-dark{
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover{
    color: $light;
  }
}

.btn-outline-success {
  background-color: rgba($success, .1);
}
.btn-outline-danger {
  background-color: rgba($danger, .1);
}

.btn-md {
  padding: 1.1rem 1rem;
  border-radius: $border-radius;
  font-size: 1.1rem;
}

.btn-move {
  padding: .8rem;
  border-radius: 50%;
  &:hover {
    background-color: rgba($primary, .1);
  }
}

button.btn-drag:not(:disabled)  {
  cursor: move;
}
// example 1

.btn-group-example{
  .btn{
    position: relative;

    &:first-child{
      &::before{
        display: none;
      }
    }

    &:before{
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}


// Example 2

.btn-label{
  position: relative;
  padding-left: 4.3rem;
  height: 3.4rem;
  display: flex;
  align-items: center;

  .label-icon{
    position: absolute;
    width: 3.4rem;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-light{
    .label-icon{
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }

  .label-danger {
    background-color: rgba($danger, .1);
    color: $danger;
  }
  .label-success {
    background-color: rgba($success, .1);
    color: $success;
  }
  .label-primary {
    background-color: rgba($primary, .1);
    color: $blue-800;
  }
}

.btns-update {
  .d-inline {
    + .d-inline {
      margin-left: 3.8rem;
    }
  }

  .btn {
    font-size: 1.1rem;
    padding-bottom: 3px;
    border-bottom: 1px solid currentColor;
    border-radius: 0;
    text-decoration: none;
  }
}