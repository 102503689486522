//
// Form Wizard
//
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';


.kyc-wizard {
  min-height: 262px;
}

.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.wizard-header {
  display: none;
}

@mixin shadow($level: 1) {
  @if $level==1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  @else if $level==2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  @else if $level==3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  @else if $level==4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  @else if $level==5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin breakpoint($class) {
  @if $class==xs {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $class==sm {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $class==md {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $class==lg {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

.wizard-icon-container.tab_shape,
.wizard-icon-circle.md {
  &:focus {
    outline: none !important;
  }
}

.wizard-icon-circle.checked {
  color: $primary;
}

.vue-form-wizard.md .wizard-icon-circle.tab_shape {
  height: 50px !important;
}