// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-#{$color} {
        &.bg-soft{
            background-color: rgba(($value), 0.25) !important;

        }
    }
}

.bg-white {
    background-color: #fff;
}

.bg-none {
    background-color: transparent;
}

.bg-blue-800 {
    background-color: $blue-800;
    color: #fff;
}