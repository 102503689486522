// 
// _table.scss
// 

.table {
  line-height: 1.4;

  th {
      font-weight: $font-weight-bold;
  }

  th,
  td {
    &:first-child {
      padding-left: 2.6rem;
    }

    &:last-child {
      padding-right: 2.8rem;
    }
  }

  th {
    padding-bottom: .8rem;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}


.table-hover > {

  tbody {
    > tr{
      cursor: pointer;
      &:hover {
        box-shadow: .2rem .2rem 0 $gray-400;
      }
    }
  }
}


.table-responsive {
  @include media-breakpoint-up(lg) {
    overflow: visible;
  }
}

.table-card {
  width: 100%;
  th, td {
    padding-top: .6rem;
    padding-bottom: .6rem;
  }
}

.table-spacing {
  margin-bottom: 2.5rem;
}

.table-section {
  td, th {
    border-bottom: 1px solid $gray-400;
    padding-bottom: 2.3rem;
  }

  + tr {
    td, th {
      padding-top: 2.5rem;
    }
  }
}

.main-content {
  .datatables {
    thead {
      tr {
        th{
          > div {
            display: inline-block;
          }
          &:after {
            content: "";
            background-image: url(/images/icons/sort.svg);
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 1;
            width: .7rem;
            height: .9rem;
            position: relative;
            top: auto;
            right: auto;
            margin-left: 1.4rem;
            display: inline-block;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
}